// 订单管理
<template>
  <div class="card">
    <div class="card_search tw-flex tw-items-center">
      <el-input
        v-model="condition.keywords"
        size="small"
        suffix-icon="el-icon-search"
        style="width: 240px;"
        placeholder="请输入订单号、用户、提供商搜索"
        @keyup.native.enter="onSearch">
      </el-input>
      <el-date-picker
        v-model="condition.orderDate"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        size="small"
        type="daterange"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="width: 240px;margin:0 10px;"
        @change="onSearch">
      </el-date-picker>
      <el-select
        v-model="condition.logicStatus"
        size="small"
        placeholder="请选择订单状态"
        :clearable="true"
        style="width: 160px;"
        @change="onSearch">
        <el-option
          v-for="item in statusList"
          :key="item.id"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="headerCellStyle"
      @row-click="rowClick">
      <el-table-column
        label="订单编号"
        prop="orderNo">
      </el-table-column>
      <el-table-column
        label="需求名称"
        prop="title">
      </el-table-column>
      <el-table-column
        label="下单时间"
        prop="createdAt">
      </el-table-column>
      <!-- <el-table-column
        label="价格"
        prop="budget">
        <template slot-scope="scope">
          <span class="tw-font-bold tw-text-primary-text">
            {{ scope.row.budget === '面议' ? '面议' : scope.row.budget + '元' }}
          </span>
        </template>
      </el-table-column> -->
      <el-table-column
        label="报价"
        prop="totalAmount">
        <template slot-scope="scope">
          <span class="tw-font-bold tw-text-primary-text">
            {{ scope.row.totalAmount }}元
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="订单状态"
        prop="logicStatus">
        <template slot-scope="scope">
          <el-tag :type="elTags(scope.row).type">
            {{elTags(scope.row).label}}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="card_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      loading: false,
      condition: {
        keywords: '',
        orderDate: [],
        logicStatus: ''
      },
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    statusList () {
      return [
        { label: '对接中', value: globalVar.REQUIRE_ORDER_STATUS_1 },
        { label: '已完成', value: globalVar.REQUIRE_ORDER_STATUS_2 }
      ]
    },
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    elTags () {
      return (item) => {
        return {
          type: {
            [globalVar.REQUIRE_ORDER_STATUS_1]: 'success',
            [globalVar.REQUIRE_ORDER_STATUS_2]: 'info'
          }[item.logicStatus],
          label: {
            [globalVar.REQUIRE_ORDER_STATUS_1]: '对接中',
            [globalVar.REQUIRE_ORDER_STATUS_2]: '已完成'
          }[item.logicStatus]
        }
      }
    }
  },
  mounted () {
    this.onSearch()
  },
  methods: {
    onSearch () {
      this.loading = true
      api.getRequireOrderList({
        keywords: this.condition.keywords,
        logicStatus: this.condition.logicStatus,
        startAt: this.condition.orderDate && this.condition.orderDate.length ? this.condition.orderDate[0] : '',
        endAt: this.condition.orderDate && this.condition.orderDate.length ? this.condition.orderDate[1] : '',
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    rowClick (row, column, event) {
      const { href } = this.$router.resolve({
        path: `/order-manage/require-order/detail/${row.id}`
      })

      window.open(href, '_blank')
    },
    pageNumChange (page) {
      this.onSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 980px;
  padding: 30px 40px;
  background: #FFFFFF;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &_search {
    margin-bottom: 20px;
  }
  &_pagination {
    margin-top: 20px;
    height: 40px;
  }
}
</style>
